import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";
// images
import appcalendar from '../../Images/appcalendar.png'
import appdropshipping from '../../Images/appdropshipping.png'
import appsearch from '../../Images/appsearch.png'
import appwallet from '../../Images/appwallet.png'
import GarageImg1 from '../../Images/GarageImg1.jpg'
import GarageImg2 from '../../Images/GarageImg2.jpg'
import GarageImg3 from '../../Images/GarageImg3.jpg'
import GarageImg4 from '../../Images/GarageImg4.jpg'
import book from '../../Images/book.png'
import bgnobgs from '../../Images/bgnobgs.png'
import AreaData from '../../Components/ServicesPage/AreaData'
import HomeAdvisor from '../../Images/HomeAdvisor.png'
import BBB from '../../Images/BBB.png'
import Angie from '../../Images/Angie.png'
import YELP from '../../Images/YELP.png'
import Google from '../../Images/Google.png'
import Trust from '../../Images/Trust.png'
import Porch from '../../Images/Porch.png'
import LocationData from '../../Components/LocationAll/LocationData'

export default function HomePage({ navigateTo, FaqList, Blogs }) {

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="HomePage">
        <Helmet>
            {/* <!-- TITLE -->  */}
            <title>Fontana, CA Garage Door | Expert Garage Door Services | 951-612-9362</title>
            <meta name="title" property="title" content="Fontana Garage Door | Expert Garage Door Services | 951-612-9362" data-react-helmet="true" />
            <meta name="og:title" property="og:title" content="Fontana Garage Door | Expert Garage Door Services | 951-612-9362" data-react-helmet="true" />
            {/* <!-- META --> */}
            <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
            <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
            <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
            <meta name="og:url" property="og:url" content="https://www.garagedoorFontana.org/" data-react-helmet="true" />
            <meta name="description" content="Fontana Garage Door provides top-quality garage door services including repair, installation, and maintenance. Trust our experienced professionals for all your garage door needs." data-react-helmet="true" />
            <meta name="og:description" property="og:description" content="Fontana Garage Door provides top-quality garage door services including repair, installation, and maintenance. Trust our experienced professionals for all your garage door needs." data-react-helmet="true" />
            <meta name="og:site_name" property="og:site_name" content="Fontana, CA Garage Door" data-react-helmet="true" />
            <meta name="keywords" content="Garage Door, Garage Door Repair, Garage Door Installation, Garage Door Maintenance, Garage Door Services" data-react-helmet="true" />
        </Helmet>
        
        {/* MainBanner */}
        <div className="MainBanner">
            <div className="MainBannerLeft">
                <h1>Your Trusted Garage Door Experts for Over 50 Years in Fontana</h1>
                <h2>Garage Door Services in Fontana, California</h2>
                <a href="tel:951-612-9362">Call Us Now!</a>
                <a href="/book">SCHEDULE ONLINE<img src={book} alt="Garage Door Services in Fontana, California" /></a>
            </div>
            <div className="MainBannerRight">
                <img src={bgnobgs} alt="Garage Door Services In Fontana, CA" />
                <div className="MainBannerRightBubble1"></div>
                <div className="MainBannerRightBubble2"></div>
            </div>
        </div>
        
        <div className="BookAnAppointmentMainComponentEmergery">
            <div className="BookAnAppointmentMainComponentRates">
                <div className="BookAnAppointmentMainComponentRatesImages">
                    <img src={YELP} alt="Fontana Garage Door Yelp Review" />
                    <img src={Angie} alt="Fontana Garage Door Angie Review" />
                    <img src={HomeAdvisor} alt="Fontana Garage Door HomeAdvisor Review" />
                    <img src={BBB} alt="Fontana Garage Door BBB Review" />
                    <img src={Google} alt="Fontana Garage Door Google Review" />
                    <img src={Porch} alt="Fontana Garage Door Porch Review" />
                    <img src={Trust} alt="Fontana Garage Door Trust Review" />
                </div>
                <h1>Customer Testimonials</h1>
                <h2>⭐⭐⭐⭐⭐</h2>
                <h3>
                    "I’ve used Fontana Garage Door for repairs and installations, and I’m consistently impressed with their service. They use quality materials and pay great attention to detail. My garage door functions perfectly, and I value their dedication to customer satisfaction."
                    <a href={`/reviews/${LocationData.find(a => a.City == ("Fontana").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>MORE FROM OUR CUSTOMERS &gt;</a>
                </h3>
            </div>
            <span className="GoogleButt">
                <h6>5 Stars - Based on 3,618 Reviews</h6>
                <h6 className="GoogleButtBooms">
                    Within 5 mi • Within 20 mi • Open now • Residential • Top rated • Prices • Online estimates • Replacement
                </h6>
            </span>
            <div className="BookAnAppointmentMainComponentEmergeryBox">
                <a href="tel:951-612-9362">For emergency service call: 951-612-9362</a>
            </div>
        </div>
        
        <div className="MainBanner2Top2">
            <div className="MainBanner2TopBox">
                <h2>OUR GARAGE DOOR SERVICES</h2>
                <div className="MainBanner2TopBoxMenu">
                    <a href="/service/Garage-Door-Repair" className="MainBanner2TopBoxBox">
                        <img src={appcalendar} alt="Garage Door Repair" />
                        <h3>Garage Door Repair</h3>
                        <h2>Expert garage door repair ensures your system operates smoothly, providing safety and security for your home.</h2>
                        <h4>EXPLORE THIS SERVICE</h4>
                    </a>
                    <a href="/service/Garage-Door-Spring-Replacement" className="MainBanner2TopBoxBox">
                        <img src={appdropshipping} alt="Garage Door Springs Replacement" />
                        <h3>Garage Door Springs Replacement</h3>
                        <h2>Replacing worn-out garage door springs is crucial for safe operation and preventing accidents.</h2>
                        <h4>EXPLORE THIS SERVICE</h4>
                    </a>
                    <a href="/service/Garage-Door-Opener-Installation" className="MainBanner2TopBoxBox">
                        <img src={appsearch} alt="Garage Door Opener Installation" />
                        <h3>Garage Door Opener Installation</h3>
                        <h2>Professional installation enhances convenience and improves security for your garage door.</h2>
                        <h4>EXPLORE THIS SERVICE</h4>
                    </a>
                    <a href="/service/Garage-Door-Installation" className="MainBanner2TopBoxBox">
                        <img src={appwallet} alt="Garage Door Installation" />
                        <h3>Garage Door Installation</h3>
                        <h2>A quality installation boosts curb appeal and adds value and security to your property.</h2>
                        <h4>EXPLORE THIS SERVICE</h4>
                    </a>
                </div>
            </div>
        </div>
    
        <div className="MainBanner2">
            <div className="MainBanner2TopTop">
                <div className="MainBanner2TopTopLeft">
                    <img src={GarageImg3} alt="Garage Door In Fontana, CA" />
                </div>
                <div className="MainBanner2TopTopRight">
                    <h4>LEAVE GARAGE DOOR CARE TO THE EXPERTS</h4>
                    <h5>WITH Fontana GARAGE DOOR, YOU RECEIVE MORE THAN JUST A SERVICE—YOU GAIN COMPLETE PEACE OF MIND.</h5>
                    <div className="MainBanner2TopBox">
                        <h2>REPUTATION</h2>
                        <h3>Trusted garage door professionals in Fontana, California, since 1975. Exceptional craftsmanship backed by decades of experience.</h3>
                    </div>
                    <div className="MainBanner2TopBox">
                        <h2>TRANSPARENCY</h2>
                        <h3>Honest pricing with no hidden fees—our garage door services are available any time, including holidays.</h3>
                    </div>
                    <div className="MainBanner2TopBox">
                        <h2>EFFICIENCY</h2>
                        <h3>Fast same-day and emergency garage door services in Fontana, California, available year-round to meet your needs.</h3>
                    </div>
                    <div className="MainBanner2TopBox">
                        <h2>QUALITY</h2>
                        <h3>Comprehensive garage door solutions, from installation to repairs, using the latest technology and advanced techniques.</h3>
                    </div>
                </div>
            </div>
    
            {/* Title */}
            <div className="MainBanner2TopTopBtn">
                <a href="/book">SCHEDULE ONLINE<img src={book} alt="Garage Door Services In Fontana, CA" /></a>
                <a href="tel:951-612-9362">CALL NOW 951-612-9362</a>
            </div>
        </div>
        
        <div className="MainBanner3">
            <div className="MainBanner2Bottom">
                <div className="MainBanner2BottomBox">
                    <div className="MainBanner2BottomBoxLeft">
                        <h2>Garage Door Repair Fontana, CA</h2>
                        <h3>
                            Exceptional Garage Door Repair Services in Fontana, CA
                            <br /><br />
                            Welcome to Fontana Garage Door, your trusted provider of professional garage door repair services. Our skilled technicians are dedicated to diagnosing and fixing any issues to ensure safe and efficient operation. We understand that a malfunctioning garage door can be a major inconvenience, and we are committed to providing prompt and reliable service.
                            <br /><br />
                        </h3>
                        <a href="/service/Garage-Door-Repair">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBoxRight">
                        <img src={GarageImg1} alt="Garage Door Repair Fontana, CA" />
                    </div>
                </div>
                <div className="MainBanner2BottomBox">
                    <div className="MainBanner2BottomBoxLeft">
                        <h2>Garage Door Springs Replacement Fontana, CA</h2>
                        <h3>
                            Reliable Garage Door Springs Replacement Services in Fontana, CA
                            <br /><br />
                            At Fontana Garage Door, we specialize in garage door springs replacement to ensure proper functionality. Worn or broken springs can pose safety hazards, and our experienced technicians are equipped to handle replacements efficiently. We prioritize your safety and convenience, offering prompt service for all your garage door needs.
                            <br /><br />
                        </h3>
                        <a href="/service/Garage-Door-Springs-Replacement">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBoxRight">
                        <img src={GarageImg2} alt="Garage Door Springs Replacement Fontana, CA" />
                    </div>
                </div>
            </div>
            <div className="MainBanner2Bottom">
                <div className="MainBanner2BottomBox">
                    <div className="MainBanner2BottomBoxLeft">
                        <h2>Garage Door Opener Installation Fontana, CA</h2>
                        <h3>
                            Professional Garage Door Opener Installation Services in Fontana, CA
                            <br /><br />
                            Welcome to Fontana Garage Door, your reliable partner for garage door opener installation. Our expert technicians are skilled in installing a variety of garage door openers to suit your needs. We ensure a seamless installation process and provide guidance on operating your new opener efficiently.
                            <br /><br />
                        </h3>
                        <a href="/service/Garage-Door-Opener-Installation">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBoxRight">
                        <img src={GarageImg3} alt="Garage Door Opener Installation Fontana, CA" />
                    </div>
                </div>
                <div className="MainBanner2BottomBox">
                    <div className="MainBanner2BottomBoxLeft">
                        <h2>Garage Door Installation Fontana, CA</h2>
                        <h3>
                            Exceptional Garage Door Installation Services in Fontana, CA
                            <br /><br />
                            At Fontana Garage Door, we provide top-notch garage door installation services for homeowners. Our team helps you choose the perfect garage door that complements your home’s aesthetics while ensuring optimal security and functionality. Trust us for quality workmanship and customer satisfaction.
                            <br /><br />
                        </h3>
                        <a href="/service/Garage-Door-Installation">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBoxRight">
                        <img src={GarageImg4} alt="Garage Door Installation Fontana, CA" />
                    </div>
                </div>
            </div>
        </div>
    
        <div className="MainBanner5">
            <div className="MainBanner5Left">
                <h1>How to Get in Touch with Garage Door Services in Fontana?</h1>
                <div className="MainBanner5LeftBox">
                    <h2>1</h2>
                    <div className="MainBanner5LeftBoxRigth">
                        <h3>Contact Us</h3>
                        <h4>Reach out to our friendly team by phone or visit our website to book your garage door service in Fontana.</h4>
                    </div>
                </div>
                <div className="MainBanner5LeftBox">
                    <h2>2</h2>
                    <div className="MainBanner5LeftBoxRigth">
                        <h3>Share Your Information</h3>
                        <h4>Provide your address and any relevant details about your garage door to help our team prepare effectively.</h4>
                    </div>
                </div>
                <div className="MainBanner5LeftBox">
                    <h2>3</h2>
                    <div className="MainBanner5LeftBoxRigth">
                        <h3>Explain Your Needs</h3>
                        <h4>Send us images of your garage door so we can assess its condition and give you a precise service estimate.</h4>
                    </div>
                </div>
                <div className="MainBanner5LeftBox">
                    <h2>4</h2>
                    <div className="MainBanner5LeftBoxRigth">
                        <h3>Set Up Your Appointment</h3>
                        <h4>Let us know the urgency of your situation, and we will find a suitable date and time for our visit.</h4>
                    </div>
                </div>
                <div className="MainBanner5LeftBox">
                    <h2>5</h2>
                    <div className="MainBanner5LeftBoxRigth">
                        <h3>Get Your Customized Quote</h3>
                        <h4>Our local technician will evaluate your needs and provide a tailored quote based on our standard rates.</h4>
                    </div>
                </div>
                <a href="tel:951-612-9362">CALL NOW 951-612-9362</a>
                <a href="/book">SCHEDULE ONLINE</a>
            </div>
            <div className="MainBanner5Right">
                <img src={GarageImg2} alt="Garage Door Near Me In Fontana, California" />
                <div className="MainBanner5RightBubble1"></div>
                <div className="MainBanner5RightBubble2"></div>
            </div>
        </div>
    
        <div className="MainBanner6">
            <div className="MainBanner6Left">
                <h2>GOT QUESTIONS? WE'VE GOT ANSWERS.</h2>
                <h3>Explore Our FAQs for Your Garage Door Needs or Watch Our Latest Videos.</h3>
                <a href="/faq">READ OUR GARAGE FAQS</a>
            </div>
            <div className="MainBanner6Right">
                <img src={bgnobgs} alt="Garage Door In Fontana, CA" />
            </div>
        </div>
    
        <div className="MainBanner7">
            <h1>Real Testimonials from Our Valued Clients</h1>
            <div className="MainBanner7Maein">
                <div className="MainBanner7Box">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>I had a fantastic experience with Fontana Garage Door. The team was punctual, professional, and very thorough. They repaired my garage door quickly and offered great maintenance tips. I feel much safer knowing my garage door is in excellent condition!</h2>
                    <h4>John M.</h4>
                </div>
                <div className="MainBanner7Box">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>Fontana Garage Door exceeded my expectations. Their technicians were friendly and knowledgeable, explaining each step of the repair process. They identified and fixed a small issue I wasn't aware of. I highly recommend their services!</h2>
                    <h4>Sarah T.</h4>
                </div>
                <div className="MainBanner7Box">
                    <h3>⭐ ⭐ ⭐ ⭐ ⭐</h3>
                    <h2>I’ve relied on Fontana Garage Door for both repairs and installations, and I’m always impressed with their level of service. They use high-quality materials and are detail-oriented. My garage door has never functioned better!</h2>
                    <h4>David R.</h4>
                </div>
            </div>
            <a href="/reviews">MORE FROM OUR CUSTOMERS &gt;</a>
        </div>
    
        <div className="MainBanner4">
            <div className="MainBanner4Right">
                <h1>OUR GARAGE DOOR SERVICES</h1>
                <h2>At Fontana Garage Door, we provide a comprehensive range of garage door services, including repair, installation, maintenance, and opener solutions. Our certified technicians ensure your garage door operates safely and efficiently. We utilize high-quality materials and advanced equipment to deliver outstanding results, enhancing the security and convenience of your home.</h2>
    
                <div className="MainBanner4RightBenefits">
                    {AreaData.map((A, index) =>
                        <a href={A.Path} className="Header1BoxMenuBox" key={index}>
                            {A.TitleShortVery}
                        </a>
                    )}
                </div>
            </div>
        </div>
    
        <div className="BlogsListFaq">
            <div className="BlogsListFaqHead">
                <h1>Frequently Asked Questions</h1>
                <a href="/faq">View All</a>
            </div>
            <div className="BlogsListFaqList">
                {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                    <article key={index} className="FaqListBox">
                        <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`}>
                            <h1>{A.question}</h1>
                            <h3><span> {A.category}</span></h3>
                        </a>
                    </article>
                )}
            </div>
        </div>
        
        <div className="BlogsListReview">
            <div className="BlogsListReviewHead">
                <h1>Related Articles</h1>
                <a href="/blog">View All</a>
            </div>
            <div className="BlogsListReviewList">
                {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>
                    <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox">
                        <article>
                            <div className="BlogsListReviewBoxLeft">
                                <img src={A.Photos} alt={A.BlogPath} />
                            </div>
                            <div className="BlogsListReviewBoxRight">
                                <h1>{A.Title}</h1>
                                <span>
                                    <h5>{readingTime(A)} MIN READ</h5>
                                    <h4>{A.Date}</h4>
                                </span>
                            </div>
                        </article>
                    </a>
                )}
            </div>
        </div>
    </div>
    
    )
}

