const LocationReviewsData = [
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Douglas Jones",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Martin Mccormick",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joseph R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Frank Compton",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Cassandra Mccoy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Timothy J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Walker",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alex R.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Brian Edwards",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Margaret A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.8,
        "customer_name": "Jessica Marshall",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin V.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Chad Perez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carolyn L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Emily Rios MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Heidi W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Lindsay Payne",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sharon M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Latoya Adams",
        "service_type": "Garage Door Opener",
        "tech_name": "Lisa L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Jeffery George",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sophia J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.8,
        "customer_name": "Brittney Solomon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Nathaniel T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Baker",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amanda G.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Rhonda Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Mariah E.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Amanda Kennedy",
        "service_type": "Garage Door Opener",
        "tech_name": "Crystal P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Nicholas Caldwell",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sarah C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.8,
        "customer_name": "Cynthia Fields",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anna C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Jared Haynes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alexa W.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Paul Weeks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Bradley K.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Ashley Prince",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Heather L.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Faith Jones",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Steven M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Victoria Boyd",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephanie B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Jared King",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Walter G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Joshua Schroeder",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Elizabeth F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Dr. Jennifer Jackson DVM",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Robin Guerrero",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth E.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Michael Schmidt",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amy C.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Ryan Lane",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brittany M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Jeremy Moore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daniel C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Linda Bell MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Kenneth J.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Joy Mcgee",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Maurice H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Jessica Harris",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ashley M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Frank Kaufman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Russell D.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Tiffany Wilkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Courtney W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Sheila Morgan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tiffany S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Johnathan Murphy",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dillon C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-12",
        "review_rate": 5.0,
        "customer_name": "Clayton Flores",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandon S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Zachary Mills",
        "service_type": "Garage Door Insulation",
        "tech_name": "Joanna C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Dawn Willis",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Yolanda H.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Paul Hall",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Shawn J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Brandi Sullivan",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anita B.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Heather Klein",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark K.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Andrea Pineda",
        "service_type": "Garage Door Off Track",
        "tech_name": "Tracy M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Ryan Washington",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Deborah D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Debbie Barrera DDS",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Rebecca C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Casey Heath",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Madeline T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Laurie Miller",
        "service_type": "Garage Door Installation",
        "tech_name": "Alex M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Cheryl Miller",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rhonda L.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.8,
        "customer_name": "Brandon Ingram",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Timothy R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "David Schwartz",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Steven D.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Leslie Sweeney",
        "service_type": "Residential Garage Door Services",
        "tech_name": "John S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Helen Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katherine G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "William Martinez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mark R.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Mindy Rodriguez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kenneth S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Laurie Jones",
        "service_type": "Garage Door Repair",
        "tech_name": "Justin G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Hannah Levine",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sherry C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Russell Sims",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Casey W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Karen Jimenez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michele A.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Christopher Oneill",
        "service_type": "Garage Door Opener",
        "tech_name": "Elizabeth G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Megan Osborne",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Elizabeth J.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Edward Schneider",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jamie N.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "David Mccall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ian H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Angel Brandt",
        "service_type": "Garage Door Services",
        "tech_name": "David M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Sherry Webb",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mark S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Sean Noble",
        "service_type": "Garage Door Insulation",
        "tech_name": "Katherine P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Brian Molina Jr.",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cynthia D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Mrs. Alice Goodman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brenda G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Jennifer Simmons",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Neil O.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Leonard Wolfe",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amy L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.8,
        "customer_name": "Derrick Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ronald J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "April Vargas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Richard D.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Shawn Pitts",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melvin W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Robert Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "William C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Brent Sims",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Bruce B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.8,
        "customer_name": "Roy Lewis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Darren S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Teresa Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amy F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Lisa Hernandez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Michael Cummings",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sara J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "Christopher White",
        "service_type": "Garage Door Repair",
        "tech_name": "Thomas M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Sarah Moon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Patrick Harris",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "James Y.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Carlos Hunter",
        "service_type": "Garage Door Repair",
        "tech_name": "James G.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Morgan Martinez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Bethany P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Jennifer Hall",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Justin C.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Alexander Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jesus M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Erica Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicholas M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Matthew Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lydia M.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Tracy Peterson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kimberly S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-11",
        "review_rate": 5.0,
        "customer_name": "Brian Clark",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Jessica Booker",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tiffany T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Karen Reed",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sandra D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Eric Mosley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Brenda Dodson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samuel R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Jody Acevedo",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kelly S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Ryan Kennedy",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sydney M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.8,
        "customer_name": "Erica Moyer",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Crystal T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "James Black",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alyssa W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.8,
        "customer_name": "Kevin Acevedo",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Joshua Robinson",
        "service_type": "Garage Door Services",
        "tech_name": "Catherine G.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Wesley Burns",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Michele Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Mark F.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Scott Wu",
        "service_type": "Garage Door Installation",
        "tech_name": "Arthur R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Amber Ball",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Charles S.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Casey Davidson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brandon C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Jonathan Bartlett",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth R.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Terry Cisneros",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Amber Rose",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Susan C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Stephanie Wall",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Olivia C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Lee Barnes",
        "service_type": "Garage Door Services",
        "tech_name": "Julie J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Rachel Mcmahon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Natalie L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Randy Clements",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Gabriella T.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Megan Carson",
        "service_type": "Garage Door Opener",
        "tech_name": "Jasmine G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Ashley Wilson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicole B.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Jesse Daniels",
        "service_type": "Garage Door Insulation",
        "tech_name": "Patricia C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Natalie Howard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kyle A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Sean Barron",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Anthony Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Tony S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Tom Barrett",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kristin S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Charles Morales",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Hector S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-14",
        "review_rate": 5.0,
        "customer_name": "Tracy Foster",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Justin M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Gabriella Salinas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Juan Foster",
        "service_type": "Garage Door Repair",
        "tech_name": "John J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Thomas Caldwell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Raymond B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Donna Hardin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Monica M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Amber Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sandra A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-17",
        "review_rate": 5.0,
        "customer_name": "Randall Hernandez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Daniel Wilson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dana C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Theresa Alexander DDS",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Casey H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-25",
        "review_rate": 5.0,
        "customer_name": "Hayley Shaw",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Denise W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.8,
        "customer_name": "Timothy Perez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Howard C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Cheryl Henderson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anthony W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Jorge Mathis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rachel C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Faith Bowman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Charles S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Michael Daniels",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Desiree F.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Gabrielle Ross",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joshua S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Deanna Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Sarah Cox",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anthony B.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 5.0,
        "customer_name": "Nicole Davis",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kenneth S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Angel Washington",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.9,
        "customer_name": "Laura Trujillo",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan H.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Mary Peterson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tony R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Kaylee Young",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joshua H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Veronica Rush",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ryan B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Victoria Hill",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kenneth C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Jeremy Koch",
        "service_type": "Garage Door Services",
        "tech_name": "Pamela G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Matthew Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Todd B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Caleb Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Donna P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Kimberly Friedman",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nicole O.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.9,
        "customer_name": "Henry Brooks",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephanie P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Kimberly Allen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Crystal M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.9,
        "customer_name": "Katherine Jordan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Craig M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Shawn Cox",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Michael H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Jesus Ramirez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rachel S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Robert Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amanda T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Johnathan Sanders",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Nathaniel J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Kurt Martin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Marc T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Natalie Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Joe K.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Singh",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mary G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Robert Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jodi S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Roger Williamson",
        "service_type": "Garage Door Services",
        "tech_name": "Maria O.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Jaime Campbell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stephanie P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Timothy Stevens",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle W.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Monica Williams",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Jeffery Bailey",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Kimberly Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Todd Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jeffrey R.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Traci Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Beverly C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Alan Williams",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gregory B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Cassandra Martin",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gregory C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Jasmine Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alan C.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Rebecca Combs",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Daniel H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Amanda Bell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Stephanie W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Donald Villegas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jerry R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Louis Cook",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charles S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Warner",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "John Phillips",
        "service_type": "Garage Door Opener",
        "tech_name": "Ryan W.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Travis Nichols",
        "service_type": "Garage Door Off Track",
        "tech_name": "Susan R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Terri Mercer",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew J.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Jacob Bailey",
        "service_type": "Garage Door Services",
        "tech_name": "Teresa G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Melissa Harris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert I.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Dorothy Bell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donald M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Brandon Ramsey",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melanie S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Allison Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michelle G.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Thomas Padilla",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Pamela G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Julia Love",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jill S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Andrew Hill",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Pamela A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Leslie Nicholson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Tony Murphy",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brian H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Kristin Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jeff R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Diana Mann",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Anthony Fischer",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Carlos W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Christine Johnston",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sherri L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Mary Diaz",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua S.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Steve Kelly",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cory M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Melissa Zhang",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jonathan G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Lynn Lin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "George M.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Jennifer Hayes",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Adam E.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Laura Stephens",
        "service_type": "Garage Door Opener",
        "tech_name": "Chris G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Shelby Snow",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Barbara H.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Patrick Tran",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Johnny M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Sabrina Harris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Heather D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Megan Li",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua B.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Brittany Smith",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Clarence Mueller",
        "service_type": "Garage Door Installation",
        "tech_name": "Joel M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Shannon Castaneda",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Thomas M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Matthew Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robin H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Alexandra Johnson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Erik R.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Linda Brown",
        "service_type": "Garage Door Insulation",
        "tech_name": "James L.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Tom Garcia",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicole L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Marie Bailey MD",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Martin L.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Patty Thomas",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nancy C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Parker Rodriguez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Holly C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Christopher Jenkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "James H.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.8,
        "customer_name": "John Davis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Allison S.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Tanya Wilkins",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jorge J.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Aaron Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Samantha C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Melinda Kirby",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Marisa R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Timothy Thompson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Susan F.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Martha Garcia",
        "service_type": "Garage Door Installation",
        "tech_name": "Andrea M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Caitlin Perry",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shawn H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Ryan Harrison",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Thomas S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "David Baker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Joseph Padilla",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer P.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Robert Cooper MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sheila H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Tonya Nunez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Kirk Marshall",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Pamela W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Lisa Solis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Chase N.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Anthony Scott",
        "service_type": "Garage Door Insulation",
        "tech_name": "Nicholas Y.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Diana Grimes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Veronica W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-31",
        "review_rate": 5.0,
        "customer_name": "George Burgess",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ann B.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Megan Steele",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brooke G.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Melanie Harrison",
        "service_type": "Garage Door Installation",
        "tech_name": "Charles B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Joseph Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Stephanie D.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Timothy Barnett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Debra J.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Kevin Sloan PhD",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kimberly F.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Marcus Harrington",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Heather White",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ariana W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-23",
        "review_rate": 5.0,
        "customer_name": "Joseph Orozco",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.8,
        "customer_name": "Sandra Perez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Heather S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Joseph Bradley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Lucas V.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Alejandra Love",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Alicia Stewart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Debra H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Jeffrey Robinson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tony F.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Nicholas Sloan",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Deborah G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Tammy Harvey",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Derrick C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Joshua Diaz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Grant W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "David Alexander",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alan L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Kathy Keith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher L.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Felicia Medina",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Maria M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Paul Graves",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Samantha W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Lucas Maxwell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Justin C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-29",
        "review_rate": 5.0,
        "customer_name": "Jimmy Rodriguez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Justin T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Lauren Gutierrez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heather B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Timothy Herrera",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John N.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.8,
        "customer_name": "David Gonzalez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Travis J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Diane Mitchell MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Angela S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.8,
        "customer_name": "Holly Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Evelyn J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Jenna Medina",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Ryan R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Diana Mitchell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Charles D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Terri Kelly",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jennifer H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Brianna Bowman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Melissa Roberts",
        "service_type": "Garage Door Services",
        "tech_name": "James C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Kristina Calhoun",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Veronica J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Paul Figueroa",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Anthony M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Joel Daniel",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tracy B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "John Thomas",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Andrew S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Christina Booth",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Marc G.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Jerry Jarvis",
        "service_type": "Garage Door Services",
        "tech_name": "Kelly H.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.8,
        "customer_name": "Craig Erickson",
        "service_type": "Garage Door Services",
        "tech_name": "Nicole B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Joseph Jackson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samantha P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Eric Trujillo",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bridget P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Vincent Wheeler",
        "service_type": "Garage Door Repair",
        "tech_name": "Christine C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Anna Adams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jesse B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "Lisa Luna",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lindsey C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-24",
        "review_rate": 5.0,
        "customer_name": "Gregory Warren",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Marvin Wood",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alison B.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Brandi Rich MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jaclyn C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Gina V.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Kyle Morse",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Elizabeth R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Beth Terry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 5.0,
        "customer_name": "Carolyn Peterson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Eric F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "William Frost",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Stephanie H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-02",
        "review_rate": 5.0,
        "customer_name": "Misty Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Juan P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Heidi Jackson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kevin K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Brandy Maynard",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erica F.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Dr. Jeffrey Vazquez DDS",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jonathan L.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Tony Valentine",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Denise M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Brandi Brown MD",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Willie V.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Gina Turner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Gregory W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Mr. William Nolan",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffery G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Alicia Jackson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kelly S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "John Perez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jonathan P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Laura Rosales",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melanie V.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Kelsey Wood",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Christopher Edwards",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Janice P.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Christina Alexander",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Anthony A.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Amy Wyatt MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Ann Wagner",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Benjamin C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Mrs. Susan Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brenda B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Jeremy Brooks",
        "service_type": "Garage Door Services",
        "tech_name": "Anne F.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Wanda Nguyen",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Eric Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Holly M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Henry",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rhonda K.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Roberto Baker",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Debra H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Jason Roman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jacob B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Julie Franklin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jaime P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Eddie Mullen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrea B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Nicole Ramirez",
        "service_type": "Garage Door Installation",
        "tech_name": "Chase H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Patricia Adkins",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda J.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Matthew Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Russell J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "James Melendez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christina R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Amy Fritz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Sean M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-22",
        "review_rate": 4.9,
        "customer_name": "Kristina Cisneros",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Krystal Martin",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sean B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Willie Vincent",
        "service_type": "Garage Door Services",
        "tech_name": "Jim E.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Dylan Andrade",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Andrew W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Grimes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristin N.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carrie M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Katie Bell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kimberly W.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Dr. John Sanchez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Julie S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Lisa Conrad",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kevin C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Keith Briggs",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven W.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Richard Hayes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Stephen G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Yolanda Hernandez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jonathan S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Christopher Carter",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ashley S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Richard Armstrong",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeff J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Jessica Li",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jerry F.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.8,
        "customer_name": "Lisa Price",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Justin W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-20",
        "review_rate": 5.0,
        "customer_name": "Stephen Lawson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Patrick J.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Timothy Martinez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dwayne K.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Tiffany Joyce",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jill C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Angie Mcmillan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Spencer J.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Diane Page",
        "service_type": "Garage Door Services",
        "tech_name": "Jasmine W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Ann Barton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brittany L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Kelsey Garcia",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Craig A.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Kelsey Miles",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffrey M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Susan Shelton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Savannah J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Diane Norris",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Vanessa E.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Sydney Foster",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chris S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Michael Schultz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jordan F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Mark Harris",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Savannah B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Eric Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jacob S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Alexis Novak",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kara D.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Sean Hamilton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bradley M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "James Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Diana M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Peter Roth",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-12",
        "review_rate": 5.0,
        "customer_name": "Christopher Perkins",
        "service_type": "Garage Door Services",
        "tech_name": "Valerie W.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Johnny Hall",
        "service_type": "Garage Door Insulation",
        "tech_name": "Hannah W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Paula Dunlap",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Beth T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Johnson",
        "service_type": "Garage Door Installation",
        "tech_name": "Mathew S.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Kathleen Wallace",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chad H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Chad Rios",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ashley W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Christopher Cook",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Carlos L.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Matthew Dunn",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Denise T.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Laura Thompson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nancy J.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.8,
        "customer_name": "Douglas Hamilton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Patrick Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tracy W.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Joseph Castro",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mallory H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "Krista Jackson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gabriel M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Stephanie Tucker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Beth Butler",
        "service_type": "Garage Door Services",
        "tech_name": "Crystal M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Johnathan Jackson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Elizabeth P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Robert Lewis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Shannon T.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Steven Potter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lauren W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Amy Bridges",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alex M.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Stephen Thomas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kelly B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.8,
        "customer_name": "Christopher Rowland",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Natalie S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Rachel Burnett",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "John K.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Mary Johnson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Roberto W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "David Carlson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Evan B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Donald Burgess",
        "service_type": "Garage Door Services",
        "tech_name": "Edwin R.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Patricia Smith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alan E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Lori Friedman",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jake H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-19",
        "review_rate": 5.0,
        "customer_name": "Jody Dunn",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Carrie M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Kristin Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Mark D.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "James Kim",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jasmine C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Amanda Krause",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Angela Cooper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Edward B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Nicole Contreras",
        "service_type": "Garage Door Opener",
        "tech_name": "Ashley W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Sharon Braun",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Adam M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-23",
        "review_rate": 5.0,
        "customer_name": "Zoe Smith",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Mark Henderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Ryan Lewis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert D.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Kevin Merritt",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Scott D.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Caitlin Stanley",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ronald Y.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Kevin Miller",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rebecca W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Samantha Gonzalez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gerald E.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Nancy Massey",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joanna S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Brian Riley",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Melissa S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "Ryan Ayala",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Valerie G.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Michelle Marsh",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jeanne P.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Jade Wood",
        "service_type": "Garage Door Installation",
        "tech_name": "James T.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.8,
        "customer_name": "Barbara Sanders",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Darren T.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Janice Howell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Megan C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Gavin Adams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jason B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Anthony Orozco",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Lisa Johnson DDS",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Steven B.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.8,
        "customer_name": "Timothy Herman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tanner T.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Robert Baker",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tamara D.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Daniel Garner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alyssa E.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Melissa Graves",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Andre H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Rachel Austin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Karen Meadows",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kyle R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Brian Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Jesse G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.8,
        "customer_name": "Connie Branch",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James N.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Mary Stokes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Levi T.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Scott Reynolds",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Stephanie M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Ashley Page",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Kristen Young",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Whitney D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Debbie Wilson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Randy M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Alexis Campbell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Joseph Warner",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandon P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Christina Trevino",
        "service_type": "Garage Door Services",
        "tech_name": "Brian S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Nathan Calhoun",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "William M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Thomas Delgado",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Anne Burns",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Kevin Parker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher H.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Anna Walker",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christopher B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.9,
        "customer_name": "Brian Higgins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brianna F.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Kim Hahn",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Scott L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Lee Manning",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Thomas R.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Teresa Norman",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Ricky Stanton",
        "service_type": "Garage Door Services",
        "tech_name": "Joanna S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-25",
        "review_rate": 5.0,
        "customer_name": "Rebecca Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jacqueline M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Mr. Michael Crawford",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Janice S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Clarence Rhodes",
        "service_type": "Garage Door Opener",
        "tech_name": "Jacob H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Emily Hines",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Steven G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Kathleen Mccullough",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Robert Stone",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Bryan K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Kyle Adams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Robert C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Richard King",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael A.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Barbara Becker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Miranda Davis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Taylor Hatfield",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Emily B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Jeremy Patrick",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melissa M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Gabriella Coleman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathleen C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Eric Harding",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandra W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Mr. Robert Moore DDS",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stephanie B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Heather Moreno",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Susan H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "James Newman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Allison H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Maria Keith DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Patrick K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Kevin Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Jennifer F.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Laurie Figueroa",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tamara Z.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Michele George",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Susan A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.8,
        "customer_name": "Kimberly Payne",
        "service_type": "Garage Door Opener",
        "tech_name": "Denise B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Everett",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brian W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David R.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Amy Brooks",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Emily P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Samantha Shaffer",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jonathan D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Anne Anthony",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.9,
        "customer_name": "Bradley Lawson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dorothy M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "James Johnson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "April K.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Raymond Ramirez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alexander B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Allison Wheeler",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Phillip J.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Dr. Tina Evans",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Crystal C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Marcia Wilson",
        "service_type": "Garage Door Services",
        "tech_name": "Marc S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Clinton Rivera",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kyle H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Deborah Wheeler",
        "service_type": "Garage Door Services",
        "tech_name": "Todd H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Morgan Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "David F.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Kimberly Perkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Michelle Haas",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Tiffany T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Leah Vega",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Eric D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Latoya Roberts",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Natalie B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Jonathan Blackburn",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jordan L.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Nicole Jordan",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sara G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Janet Brennan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "John T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Amy Lambert",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heidi P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Justin Ortiz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kristen P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Stacey Lawson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sandra W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Brandon Nelson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ian S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Heather Trujillo",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kyle F.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Katherine Franklin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Jason Gillespie",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Teresa A.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Brian Grant",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy E.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Paul Johnson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "George Andrews",
        "service_type": "Garage Door Insulation",
        "tech_name": "Scott W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Justin Sloan",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Gregory D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Kelly Henderson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Courtney M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Nicole Howard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sabrina G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Tamara Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mary P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Daniel Turner",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kevin J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Cameron Alexander",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Carolyn G.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Erika Atkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Philip G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Carrie Waters",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Catherine Morgan",
        "service_type": "Garage Door Opener",
        "tech_name": "Charles B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Barbara Clark",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Holly D.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Brittany Walker",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.8,
        "customer_name": "Phillip Ali",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Alexander W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Thomas Stone",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeff R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Brandi Johnson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bruce C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Felicia Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Jacob Sullivan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Alicia T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Chase Campos",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "James Macias",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brian W.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Randall Garcia",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Debra S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Janice Scott",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maria P.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "James Webster",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Amanda F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "James Williams",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.8,
        "customer_name": "Hayden Ross",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Katherine C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Melissa Jackson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Katherine H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Brian Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Christopher I.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Zachary Ellis",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Terry Nguyen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Becky B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Mary Wilcox",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katherine L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Robert Patel",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nicholas H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Cynthia Romero",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mary W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Christopher Hansen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tim G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.8,
        "customer_name": "Paula Cobb",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicholas I.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Kevin Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nicholas C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Joseph Lewis",
        "service_type": "Garage Door Services",
        "tech_name": "Megan L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Carolyn Moyer",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tiffany N.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Taylor Gray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tamara B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Ernest Moore",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Natalie R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-06",
        "review_rate": 5.0,
        "customer_name": "Eugene Madden",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Krista M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Dr. Ryan Patterson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dustin L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Mr. Michael Hamilton",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kelly B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Michael Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tracy M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Brittany Fuentes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John S.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Gabriel Roman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lisa M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Karen Stephens",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathon S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Sabrina Perez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard S.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Richard Jackson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kyle G.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Sherry Sweeney",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "David Rocha",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "William H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Robert Welch",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Linda G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "John Williamson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Ann Martinez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Crystal O.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.9,
        "customer_name": "Mariah York",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Jessica Ray",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Timothy C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Brenda Jackson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Karen H.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Tyler Crawford",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Veronica H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Cynthia Stewart",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Brian English",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Lisa G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Daniel Andrade",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dawn R.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Cassidy Jenkins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julia K.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Michele Myers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brittney M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Maria Webster",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Crystal F.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.9,
        "customer_name": "Mark Klein",
        "service_type": "Garage Door Repair",
        "tech_name": "Derrick M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Matthew Harrison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lindsay M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Timothy Sanders",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Deborah T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "David Stewart",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anna F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Mary Hunt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Patrick Reyes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Maurice H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Karen Morgan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Erica W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Kathleen Hicks",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ian D.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Laura Thompson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tara J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Daniel Montoya",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lauren B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Richard Ray",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lisa M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Jose Cobb",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Theresa H.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Phillip Hardin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Adam Salinas",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kevin R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Adrian Fisher",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Erica S.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "John Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Michelle Dickerson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth B.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Adrian Patterson V",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Julie H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-23",
        "review_rate": 4.9,
        "customer_name": "Brittany Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cindy G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Brett Peterson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Dennis O.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Ernest Nguyen",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Amber S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Kyle Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anthony O.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Melissa Austin",
        "service_type": "Garage Door Opener",
        "tech_name": "Nicole M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-24",
        "review_rate": 5.0,
        "customer_name": "Joshua Foster",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Abigail R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.9,
        "customer_name": "Kimberly Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Angela B.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Sarah Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew L.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Michael Holland",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Yvonne S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Marvin Conley",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Rachel W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Blake Thornton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ernest P.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Angela Evans",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jack M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Mrs. Gina Andrews",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anita D.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Spencer Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jodi T.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Krista Booth",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Donald Chavez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert W.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "Chelsea Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ryan G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.9,
        "customer_name": "Melissa Baker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dawn J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "April Torres",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sarah M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Shannon Fox",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patricia B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Dawn Bush",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Margaret A.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Isabel Carrillo",
        "service_type": "Garage Door Off Track",
        "tech_name": "Maria H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-24",
        "review_rate": 5.0,
        "customer_name": "Kurt Marshall MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan R.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Kathy Schneider",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sean S.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Jasmine Kelly",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Alex Gaines",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mallory M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Nathan Santos",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brian V.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-19",
        "review_rate": 5.0,
        "customer_name": "Cynthia Cooper",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Henry W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Dylan Dean",
        "service_type": "Garage Door Repair",
        "tech_name": "Cynthia W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Cory Moore",
        "service_type": "Garage Door Services",
        "tech_name": "Austin N.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Lorraine Kent",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michelle P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Kristin Allen",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stephen J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Amy Bowman",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kenneth M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Mark Clark",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Theresa White",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ryan B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.9,
        "customer_name": "Thomas Daniel",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chad S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Clinton Randall",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Melissa B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Taylor Hopkins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Morgan T.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-08",
        "review_rate": 5.0,
        "customer_name": "Anthony Underwood",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Eric R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Christopher Clark",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mark H.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Jordan Huerta",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Craig C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Dr. Christopher Schmidt",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Hunter S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-12",
        "review_rate": 5.0,
        "customer_name": "Mark Matthews",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Susan O.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Zachary Ibarra",
        "service_type": "Garage Door Installation",
        "tech_name": "Gabrielle S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Kyle Campbell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Breanna Webb MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Nathan S.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.8,
        "customer_name": "Wayne Sanchez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Diane S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-25",
        "review_rate": 5.0,
        "customer_name": "Christopher Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew M.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "John Hamilton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Beverly Y.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Sandra Vega",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Frank B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Jennifer Mcdonald",
        "service_type": "Garage Door Installation",
        "tech_name": "Peggy P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "James Harrell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Dawn W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "David Holloway",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Phillip L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Mary Walsh",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Crystal M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Andrew Terry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mark D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Justin Figueroa",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Dr. Michael Valentine",
        "service_type": "Garage Door Off Track",
        "tech_name": "Eddie P.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Mark Stout",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandi G.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-07",
        "review_rate": 5.0,
        "customer_name": "Bryan Miller",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Scott Higgins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tracy S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Theresa Martinez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Justin C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Jessica Jenkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Adam S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Brianna Nelson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patricia G.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.8,
        "customer_name": "Dorothy Bass",
        "service_type": "Garage Door Services",
        "tech_name": "Brian S.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.8,
        "customer_name": "Thomas Johnson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Evan P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Brett Morales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Rebecca W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "William Lewis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Louis W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Shelby Burton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael P.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Laura Jones",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Alan S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Robert Ford",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Kyle M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Krista Hart",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kathleen D.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Lisa Miranda",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ryan H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Kathleen Snyder",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Amy Mullins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Chad D.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Paul Sims",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Susan J.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Bradley Bautista",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew S.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Mrs. Brenda Schaefer",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Scott R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Brian Green",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nicholas L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Johnathan Flores",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dennis H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.8,
        "customer_name": "Brett Johnson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Amy K.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Leah Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Anthony P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Yolanda Woods",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marc S.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Austin Jones",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Craig S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Katherine Dyer",
        "service_type": "Garage Door Repair",
        "tech_name": "Christopher H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.8,
        "customer_name": "Patricia Dunn",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael F.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Richard Alexander",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carolyn H.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Timothy Gonzalez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jamie B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Arthur Christian",
        "service_type": "Garage Door Installation",
        "tech_name": "Erik S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Leslie Morgan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven B.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Ruth Castillo",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Lisa Jimenez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christine T.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Aaron Chen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven P.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Michelle Burton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ryan R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Tracey Haynes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Mike A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Patricia Snyder",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexandra B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Alexis Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Justin C.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Michael Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Edward L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Dr. Dawn Lozano",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Michelle B.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Jenkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joseph M.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Brittany Russell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alex M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-11",
        "review_rate": 4.9,
        "customer_name": "Sharon Powers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tonya L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Crystal Brown",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Phillip W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Lauren Diaz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anthony V.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Rachel Robinson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Matthew N.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "David Brown",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicholas S.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Joanna Robles",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lauren M.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Eric Wu",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kelly T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Christopher Pineda",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard P.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Kevin Phelps",
        "service_type": "Garage Door Installation",
        "tech_name": "Joseph T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Christopher Wilkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sharon J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Justin Dyer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Steven D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Mary Roberson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Thomas W.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Beverly Woods",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "Isaac Cooper",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica L.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Sara Smith",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Adam A.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Andrea Bryan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brandon M.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Carly Brooks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Rachel Lewis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sherry M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Donna Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carlos C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Mitchell Walker",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Katherine C.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "John Martin",
        "service_type": "Garage Door Off Track",
        "tech_name": "Holly B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Catherine Mccoy",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Phillip Rivera",
        "service_type": "Garage Door Services",
        "tech_name": "James T.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Matthew Boyle",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Charlene R.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Andrew Watts",
        "service_type": "Garage Door Installation",
        "tech_name": "Carrie D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Brenda Gill",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Katherine M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Curtis Lopez",
        "service_type": "Garage Door Installation",
        "tech_name": "Richard P.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Scott Hendrix",
        "service_type": "Garage Door Services",
        "tech_name": "Evan F.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-08",
        "review_rate": 5.0,
        "customer_name": "Lucas Ortega",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Holly W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Whitney Dickerson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael S.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Robert James",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cheryl W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Christopher Garcia",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeffrey B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Rebecca Richards",
        "service_type": "Garage Door Opener",
        "tech_name": "David P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-10",
        "review_rate": 4.8,
        "customer_name": "Daniel Collins",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Meagan A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-02-13",
        "review_rate": 5.0,
        "customer_name": "John Downs",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "James W.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Steven Hurst",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Theresa P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Teresa Ryan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Javier M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Jasmine Young",
        "service_type": "Garage Door Opener",
        "tech_name": "Lori G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Keith Martinez",
        "service_type": "Garage Door Opener",
        "tech_name": "Brandon L.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Karen Thomas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "David B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Kyle Faulkner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christopher S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Jason Brown",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Adam W.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Michelle Mendoza",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rachel F.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "John Lewis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Robert Miller",
        "service_type": "Garage Door Opener",
        "tech_name": "Eric R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Veronica Johnson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rick C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Bryan Ferguson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Katherine G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Kevin Smith",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Miguel M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Anthony Craig",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Antonio S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.8,
        "customer_name": "Michael Moore",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Manuel L.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.8,
        "customer_name": "Crystal Taylor",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily P.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Carla Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Laura B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Sabrina Wagner",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Cheryl A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Angela Lucas",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Adam F.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Charles Harrell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Samantha B.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.8,
        "customer_name": "Scott Harrison",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Denise M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-04",
        "review_rate": 5.0,
        "customer_name": "Debra Martinez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Debbie S.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Linda Mann",
        "service_type": "Garage Door Insulation",
        "tech_name": "Penny G.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-20",
        "review_rate": 5.0,
        "customer_name": "Marissa Ayala",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Meghan C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Ashley Goodwin",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Scott Hammond",
        "service_type": "Garage Door Services",
        "tech_name": "Ruben P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Tammy Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nichole K.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Susan Wyatt",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amber W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Katherine Watkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gregory C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "William Conley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patricia G.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Kimberly Cohen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-05",
        "review_rate": 5.0,
        "customer_name": "Anthony Carter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jessica A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Michael Alexander",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dylan B.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "William Gallegos",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Lisa H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Ryan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Bruce Yates",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 5.0,
        "customer_name": "Deborah Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "David E.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Bobby Harris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amanda W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Julie Flores",
        "service_type": "Garage Door Insulation",
        "tech_name": "Matthew N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Pamela Patton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jonathan W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Kelsey Brown",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Sylvia Bradley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alejandra F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Michael Osborn",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mason A.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Noah Hall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gina B.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Brandon Cabrera",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kelly C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "Claire Rodriguez",
        "service_type": "Garage Door Repair",
        "tech_name": "Gregg G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-28",
        "review_rate": 5.0,
        "customer_name": "Scott Hunter",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brittany M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-08",
        "review_rate": 5.0,
        "customer_name": "Danny Rice",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kyle L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-15",
        "review_rate": 5.0,
        "customer_name": "Erika Mcclure",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrea P.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Teresa Mendez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "David Bradshaw DDS",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Shannon R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Donald Johnson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Daniel H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Kristin Carey",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeremy D.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Rachel Bell",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christina H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Mr. Jeffrey Short",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Douglas R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Kelly Thomas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Charles F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jason Griffith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Rachel Bird",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel R.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Tyler Edwards",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Angel Hoffman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Pamela C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "David Nelson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Samantha J.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Ashley Gonzalez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kyle J.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Brian Fletcher",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John V.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Lisa Miller",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Michael E.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Deborah Bailey",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Haley W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Mrs. Teresa Mitchell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joseph H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Candice Gaines",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tyler N.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.8,
        "customer_name": "Johnny Bender",
        "service_type": "Garage Door Installation",
        "tech_name": "Michelle W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Kendra Maynard",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Randy K.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-30",
        "review_rate": 5.0,
        "customer_name": "Virginia Sanchez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicole C.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Raymond Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Karen D.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Lori Odom",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Joshua K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Danny Benton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jennifer M.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Janet Hanna",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Eric B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.8,
        "customer_name": "Desiree Moore DDS",
        "service_type": "Garage Door Services",
        "tech_name": "Belinda W.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Grace Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Alicia H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Chloe Schroeder",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Maria P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Zachary Petersen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brooke L.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Brett Velasquez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Tracy M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Brianna Monroe",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amanda S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Brandon Ramirez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jennifer S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Shannon Mcbride",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Zachary M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Kimberly Stanley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Greg C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Yvonne Hinton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jesse J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 5.0,
        "customer_name": "Madison Underwood",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Meghan L.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Christian Parker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Susan W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-23",
        "review_rate": 5.0,
        "customer_name": "Ryan Cole",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Kenneth W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Sarah Morris",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Raymond D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Stanley Boyd",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexander T.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "David Harrison",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joshua H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Kelly Acosta",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angel P.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Fred Young",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Luis Mahoney",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Vanessa G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Bobby Mcclure",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Matthew W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Brian Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "Sara Harding",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Desiree W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Brandon Craig",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lauren K.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Andrew Rogers",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jessica I.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Tamara Scott",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Caitlin M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.8,
        "customer_name": "Henry Rosales",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tracy A.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Jerry Stout",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kelsey R.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Hannah Green",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sharon S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Keith Moore",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark K.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Heather Meyer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Nicole G.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-26",
        "review_rate": 5.0,
        "customer_name": "Erica Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bonnie M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Alan Khan",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer K.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Dr. David Cole MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brandy H.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Kristin Phillips",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ian B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Lori Hamilton",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Heidi J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Patricia Perkins",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kelly S.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Jesse Sanchez",
        "service_type": "Garage Door Installation",
        "tech_name": "David S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Nicole Ingram",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Dalton S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Scott Wood",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mathew S.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Jennifer Chavez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Elizabeth F.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Robert Harmon",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cynthia H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Nancy Dennis",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Howard C.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Cynthia Perez MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christopher A.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Rebecca Reed",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jane M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Kevin Hale",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James T.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Bennett",
        "service_type": "Garage Door Repair",
        "tech_name": "Noah B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Angela Vaughn",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "James W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Jennifer Williamson",
        "service_type": "Garage Door Installation",
        "tech_name": "Erik S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Christopher Stephenson MD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Margaret W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-16",
        "review_rate": 4.8,
        "customer_name": "Tina Melton",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicholas J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.9,
        "customer_name": "Adam Flores",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Mary S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Tammy Wagner",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alexander R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Michele Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David F.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Christopher Williams",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeremy L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Miranda Johnston",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ronald C.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Sherry Vega",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sheila B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "James Wheeler",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paul P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Kirsten Ball",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy Y.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "David Burke",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Walter H.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Joseph Johnson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicole H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-18",
        "review_rate": 5.0,
        "customer_name": "Emily Riddle",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly F.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Bailey Dawson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kara H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.8,
        "customer_name": "Jesus Bell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Melissa S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Alexander Shields",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Scott G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Steven Mullins",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Danielle R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-09",
        "review_rate": 5.0,
        "customer_name": "Andrea Choi",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dawn S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Patrick Carey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Crystal J.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "David Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Samuel G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Teresa Wiggins",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jason C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Diane Dalton",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Andrew B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.9,
        "customer_name": "Catherine Middleton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brad B.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "James Choi Jr.",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Danielle N.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Claire Becker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert M.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Emily Reese",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Margaret T.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Joshua Clark",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Joshua C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Adam Cervantes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Elizabeth B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Natalie Sanchez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shaun R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Rebecca Arnold",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Scott E.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Rivera",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lisa J.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.9,
        "customer_name": "Matthew Parks",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Luis P.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Michelle Kirk",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "David Robinson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Crystal S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Kimberly Mayo",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kimberly R.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Rachel Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Steven M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Eric Sawyer Jr.",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Gerald Dyer",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Thomas Riley",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Elizabeth M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Alexis Martin",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Omar F.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-01",
        "review_rate": 5.0,
        "customer_name": "Michael Moss",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Billy S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Brittany Ryan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Richard R.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Robert Livingston",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Dale C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Troy Tate",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sherri F.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Chavez",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Vanessa Stephens MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Scott B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Tracey Fowler",
        "service_type": "Garage Door Opener",
        "tech_name": "Sean D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Keith Kennedy",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Julia H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Teresa Bell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "Heather Vargas",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeremy A.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Rebecca Williams",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Andrea D.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Rita Pierce",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Debra L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Amy King",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sheryl H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Wyatt Diaz",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Tammy Lee",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ashlee G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Nicholas Ross",
        "service_type": "Garage Door Opener",
        "tech_name": "Christy M.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Lawrence Warner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Kristen Banks",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Veronica G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Zachary Wilcox",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vickie C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Erin Lyons",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Daniel J.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Ochoa",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amy B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.8,
        "customer_name": "Joshua Peck",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Darrell S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Stacey Gonzalez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristen E.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.8,
        "customer_name": "Michael Lopez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John G.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Vicki Garcia",
        "service_type": "Garage Door Services",
        "tech_name": "Molly C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Eric Hampton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Erika N.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.8,
        "customer_name": "Gregory Foster",
        "service_type": "Garage Door Installation",
        "tech_name": "Andre J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Sara Brennan",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mark S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Mary Jacobson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-11",
        "review_rate": 5.0,
        "customer_name": "Suzanne Carr",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tyler J.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Sean Underwood PhD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tanya M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Steven Callahan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jason B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Patterson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristin N.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Betty Simpson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "David W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Megan Allison",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michele W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "John Jacobs",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Patricia Welch",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Frank R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "Wesley Perez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "April G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "Scott Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "John M.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Stephen Blackburn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brendan M.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Bryan Kennedy",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael B.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Jonathan Anderson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Joshua P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Moses",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Frank A.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Cheryl Goodman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew F.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Linda Kim",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Bryan Y.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Kristin Hunter",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Bonnie M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.8,
        "customer_name": "Julie Short",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ricardo D.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Jordan Kirby",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katelyn P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Evelyn Davis",
        "service_type": "Garage Door Installation",
        "tech_name": "Jacob O.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Kevin Steele",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Burns",
        "service_type": "Garage Door Repair",
        "tech_name": "Juan M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Frank York DVM",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mario B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-03",
        "review_rate": 5.0,
        "customer_name": "Timothy Snyder",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Laura Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cynthia F.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Joshua Perkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Shawn V.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Phillip Johnson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stuart W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Courtney Lopez DVM",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Janet Leonard",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Fernando N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Keith Morris",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Charles G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Andrew Sparks",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandra L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Sara Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Melissa Davis",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Louis S.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Timothy David",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Peggy W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.8,
        "customer_name": "Keith Mccarthy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brandon M.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Zachary Carroll",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Craig C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Joel White",
        "service_type": "Garage Door Installation",
        "tech_name": "David T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Dr. Arthur Roberts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anthony M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Karen Santiago",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bradley B.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Hector Anderson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Wesley P.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.8,
        "customer_name": "Kristina Gonzalez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anna R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Mark Parker",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Malik S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.8,
        "customer_name": "John Sullivan DDS",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Courtney J.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Anna Reese",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jorge B.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-31",
        "review_rate": 4.9,
        "customer_name": "Tiffany Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Trevor W.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Briana Hayes",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Karen L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Joshua Silva",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Heather N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Samantha Pruitt",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Anna B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Sarah Anderson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Crystal S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Cynthia Lane",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Randall T.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Mark Barton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Carla F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Kelly Glover",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brandon G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "David Sampson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Kurt M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Thomas Skinner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tiffany J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-05",
        "review_rate": 5.0,
        "customer_name": "David Morgan",
        "service_type": "Garage Door Repair",
        "tech_name": "Melinda G.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Brandi Adams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ana H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Carlos Garrison",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Edward Y.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Kristopher Kirby",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael O.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Krista Reyes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Nicholas Perry",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Nathaniel M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Ashley Little",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Megan B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Jerome Jensen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Laura S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Devon Campbell",
        "service_type": "Garage Door Opener",
        "tech_name": "Dana J.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-26",
        "review_rate": 5.0,
        "customer_name": "Linda Robles",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carlos N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Kayla Mendez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "John B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Valerie Rodriguez",
        "service_type": "Garage Door Installation",
        "tech_name": "Chelsea R.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Sean Meyer",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kristin C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Gabrielle Nielsen",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Carrie Duke",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bryan D.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-18",
        "review_rate": 4.9,
        "customer_name": "Cheryl Martin",
        "service_type": "Garage Door Opener",
        "tech_name": "Erik G.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Christian",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ashley M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Kirsten Garrett",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Joshua F.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Destiny Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samuel B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Joshua Ramos",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Carl D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "Michael Peterson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Patricia S.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Stephen Wilkerson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Nicholas H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Eugene Lopez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer C.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Jennifer Lawson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shannon F.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "William Lee",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Emily R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-03",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Jensen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rebecca B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Victoria Smith",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Andrew B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Colleen Hoffman",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "David H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Tina Harris",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Nicholas S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.8,
        "customer_name": "Annette Jenkins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Marcus Burke",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Zachary W.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Ashley Pineda",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca K.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Samantha Larson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Crystal R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Sarah Robbins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dana G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Joshua Garza",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jennifer E.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Brenda Scott",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Steven B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Brandon Dixon",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert G.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Karen Martin",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Adam R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Chad Garcia",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Crystal P.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.8,
        "customer_name": "Pamela Barrett",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jordan B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Anthony Duncan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jorge H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Jorge Brooks",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Melissa G.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Lori Warren",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "James R.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Jasmine Vazquez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lisa W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Edwin Miller",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Rebecca B.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Kimberly Vaughn",
        "service_type": "Garage Door Opener",
        "tech_name": "Thomas M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Peter Payne",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Desiree M.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Brian Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Destiny A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Tammy Cook",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Rachel D.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Bobby Richard",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Javier M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Kristina Williams",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sarah K.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Heather Campbell",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christopher L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Susan Zamora",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kim T.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Teresa Hernandez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christopher D.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Tony Wheeler",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Roberta M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Jonathan Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dakota W.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Mr. Adam Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ruben J.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Tonya Merritt",
        "service_type": "Garage Door Services",
        "tech_name": "Seth K.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.9,
        "customer_name": "Amy Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sydney A.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Timothy Bell",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Susan T.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Edwin Moyer",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Virginia C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-26",
        "review_rate": 5.0,
        "customer_name": "Jessica Walker",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori J.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Victoria Hernandez MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jennifer M.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-11",
        "review_rate": 5.0,
        "customer_name": "Megan Mclean",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Deborah R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Edwin Wyatt",
        "service_type": "Garage Door Services",
        "tech_name": "Katherine M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Amanda Hensley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Robin P.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Leroy Elliott",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Mark W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Kathy Walters",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Guy D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Raven Schmitt",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Victor T.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Brian Harris",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jessica D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Jamie Beck",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael E.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Brett French",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brett L.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Richard Scott",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeremiah M.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Kyle Beasley",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Samantha M.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Jonathan Schneider",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexander G.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Maria Maldonado",
        "service_type": "Garage Door Services",
        "tech_name": "Steven L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.8,
        "customer_name": "Cheryl Hernandez MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Andrew Little",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Andre L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Dr. Christopher Reed",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ian B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.8,
        "customer_name": "Peter Oliver",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Preston V.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "William Jimenez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Hannah O.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-10",
        "review_rate": 5.0,
        "customer_name": "Antonio Anderson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michelle L.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Robert Bray",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-05",
        "review_rate": 5.0,
        "customer_name": "Melanie Stewart",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jonathan C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-29",
        "review_rate": 5.0,
        "customer_name": "Sheila Gomez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Meredith M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Catherine Graham",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Matthew Fisher",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Aaron C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.8,
        "customer_name": "James Henry II",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Craig M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Daniel Hansen",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Linda H.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "John Peterson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sara R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Lauren Nguyen",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Alexander G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Jo Blackwell",
        "service_type": "Garage Door Opener",
        "tech_name": "Donna P.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Mr. Paul Anderson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Yesenia T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Samantha Patel",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Marc B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "John Jenkins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Michael T.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Monica Allen",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Steven B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-28",
        "review_rate": 5.0,
        "customer_name": "Courtney Thomas",
        "service_type": "Garage Door Opener",
        "tech_name": "Robert C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Cody Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christine D.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Maria Leon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Courtney G.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Matthew Harris",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alicia T.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Mark Mccall",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Monica S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Cynthia Montoya",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Charles W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Connie Reese",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sue R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Christopher Wilson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Troy K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-30",
        "review_rate": 5.0,
        "customer_name": "Robert Gonzalez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ryan G.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-23",
        "review_rate": 5.0,
        "customer_name": "Raymond Sanchez",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Matthew M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Drew Horne",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah M.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Jessica Cole",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Connie M.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Sheila Edwards",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Christian Ramirez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "John G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Tina Kirby",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sandra F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "James Dodson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephen B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 5.0,
        "customer_name": "Michael Nolan",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Tyler Peterson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melanie H.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Matthew Ryan",
        "service_type": "Garage Door Services",
        "tech_name": "Joshua C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.9,
        "customer_name": "Martin Hampton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael L.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Ricky Reyes",
        "service_type": "Garage Door Repair",
        "tech_name": "Juan B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "James Davidson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher F.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Melissa Flores",
        "service_type": "Garage Door Repair",
        "tech_name": "Samuel B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Shelia Johnson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Noah K.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-24",
        "review_rate": 5.0,
        "customer_name": "Emily Phelps",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeffrey H.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hopkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Vanessa J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Colleen Reyes",
        "service_type": "Garage Door Installation",
        "tech_name": "Bryan H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Raymond Hurst",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tina W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-05",
        "review_rate": 5.0,
        "customer_name": "Paul Sanchez",
        "service_type": "Garage Door Off Track",
        "tech_name": "James W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "David Fuller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Betty M.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Claire Collier",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Maria B.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Susan Gross",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Cassidy H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Brenda Cherry",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Ian Love",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Shawn S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Cheyenne James",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jamie B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Tyler Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.8,
        "customer_name": "Wesley White",
        "service_type": "Garage Door Insulation",
        "tech_name": "Scott B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Benjamin Bond",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jessica D.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Willie Rogers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amanda S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "Lisa Robinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Margaret W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Andrea Mullins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Ryan B.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Thomas Sanders",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Melinda L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Natasha Pittman",
        "service_type": "Garage Door Installation",
        "tech_name": "Christopher F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Laura Ibarra",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Anthony L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Ashley Boyd",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Julie M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Sarah Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Tyler G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Gregory Black",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Dana P.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 5.0,
        "customer_name": "Michael Lee",
        "service_type": "Garage Door Opener",
        "tech_name": "Heather L.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Lori Myers",
        "service_type": "Garage Door Off Track",
        "tech_name": "Craig B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Spencer Gutierrez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Lindsey W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "William Clarke",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kristy M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Austin Scott",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jacqueline W.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Sandra Avila",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey R.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.8,
        "customer_name": "Timothy Grant",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "David A.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Jacob Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Alexis Bradley",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Heather H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Daniel Ray",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Angela C.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Carrie Yoder",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tiffany B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Mark Hutchinson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jeremy S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-29",
        "review_rate": 4.8,
        "customer_name": "Mrs. Elizabeth Ortiz DVM",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Felicia H.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Chelsea Rice",
        "service_type": "Garage Door Insulation",
        "tech_name": "Richard E.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Ernest Cardenas",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Chad White",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Holly Larson",
        "service_type": "Garage Door Installation",
        "tech_name": "Jason L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Ann Powers",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ashley M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "George Gaines",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Melissa Gonzales",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-27",
        "review_rate": 5.0,
        "customer_name": "Matthew Guzman",
        "service_type": "Garage Door Off Track",
        "tech_name": "Richard H.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Sheila Lambert",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Joseph Richardson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacqueline C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-10",
        "review_rate": 5.0,
        "customer_name": "Brian Rhodes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Megan C.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Debra Harris",
        "service_type": "Garage Door Repair",
        "tech_name": "Jamie S.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Toni Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dylan C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-16",
        "review_rate": 5.0,
        "customer_name": "Alexander Shelton",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Angela C.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Nancy Johnson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kristen H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "John Burke",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Benjamin J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-09",
        "review_rate": 4.8,
        "customer_name": "Meredith White",
        "service_type": "Garage Door Off Track",
        "tech_name": "Shawn B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Courtney Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ana H.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "April Gomez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joseph G.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Arthur Peterson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Benjamin H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-25",
        "review_rate": 5.0,
        "customer_name": "Kiara Hensley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Vanessa S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.8,
        "customer_name": "Cassandra Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Julie B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.8,
        "customer_name": "Cindy Washington",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Calvin D.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Lori Marshall",
        "service_type": "Garage Door Installation",
        "tech_name": "Sandra S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Victor Garza",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Alexandra L.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Darin Zamora",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Troy C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Dana Robinson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Judy V.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Noah Juarez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Melvin Lawson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Toni L.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Cory Fowler",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Cynthia C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.8,
        "customer_name": "Cory Bryant",
        "service_type": "Garage Door Services",
        "tech_name": "Christina R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Sara Rogers",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Michael N.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Brittany Robinson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Emily W.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Benjamin Williams",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christine W.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Lori Jones",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daniel R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "David Mccoy",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christina G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Amanda Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William O.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "George Johnson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Nichole Wallace",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Anna Chung",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Barbara S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Jennifer Solis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Zachary W.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Adam Hill",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cheryl H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Adam Moore",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Beth J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "George Hobbs",
        "service_type": "Garage Door Maintenance",
        "tech_name": "James K.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Michael Bates",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Samantha H.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Melissa King",
        "service_type": "Garage Door Repair",
        "tech_name": "Steven C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Megan Taylor",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Keith C.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Sheri Lee",
        "service_type": "Garage Door Repair",
        "tech_name": "Wendy C.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-22",
        "review_rate": 5.0,
        "customer_name": "Stephanie Espinoza",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Lauren W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-26",
        "review_rate": 5.0,
        "customer_name": "Andrea Bryant",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Lindsey W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Maria Petersen",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Julie W.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Kyle Greene",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael C.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Roger Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sharon P.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Jason Tran",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Edward S.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Christine Bryant",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda O.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "William Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jenna B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Paula Thompson",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Susan T.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Laura Cross",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer T.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Leah Pace",
        "service_type": "Garage Door Insulation",
        "tech_name": "Aaron M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Katrina Lopez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Katherine J.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Jennifer Bryant",
        "service_type": "Garage Door Repair",
        "tech_name": "Chad L.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Austin Gallegos",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Teresa M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Amanda Roach",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emma G.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Robin Gomez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Sean Reid",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-19",
        "review_rate": 5.0,
        "customer_name": "Deanna Gonzalez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Priscilla C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Melissa Conner",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Becky R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Teresa Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David R.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Christina Bates",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Logan J.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Veronica Lee",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sara S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Jennifer Bowers",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jennifer F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-22",
        "review_rate": 5.0,
        "customer_name": "Micheal Hall",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kevin P.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "David Collins",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeffrey S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Joanne Hartman",
        "service_type": "Garage Door Repair",
        "tech_name": "Ricky C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-07",
        "review_rate": 5.0,
        "customer_name": "Jordan Wright",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.8,
        "customer_name": "Mrs. Teresa Lee",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sherry M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.8,
        "customer_name": "Samuel Martinez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sue H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Gregg Walker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Paul J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.8,
        "customer_name": "Ashley Robertson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Amanda G.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Jennifer Mitchell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John P.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Aaron Stevenson",
        "service_type": "Garage Door Repair",
        "tech_name": "Alexander H.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Christopher Howe",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David A.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Hannah King",
        "service_type": "Garage Door Repair",
        "tech_name": "Christian F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-04",
        "review_rate": 5.0,
        "customer_name": "Brittany Moody",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shannon C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Jon Rogers",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Matthew F.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Oscar Reeves",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Brittany H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Michelle Potter",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Norma S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Dawn Rodriguez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Timothy S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Nicole Sanders",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Robert Nichols",
        "service_type": "Garage Door Repair",
        "tech_name": "Alyssa J.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Darrell Bautista MD",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Patricia B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-04",
        "review_rate": 5.0,
        "customer_name": "Ronald Wang",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christina P.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Leslie Webb",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexis L.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Anthony Brown",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jesse D.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Dr. Christian Burton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angela C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "Lisa Ford",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joel N.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Craig Ramos",
        "service_type": "Garage Door Services",
        "tech_name": "Noah W.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Breanna Stewart",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Travis B.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Debbie Marsh",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Troy S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Caitlin Lopez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Desiree E.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.8,
        "customer_name": "Joseph Lyons MD",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Deborah R.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Olivia Brown",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kelly R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Patrick Stephens",
        "service_type": "Garage Door Opener",
        "tech_name": "Andrew G.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Samantha Roman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Juan J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Heather Duran",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cody A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Joshua Hawkins",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kimberly D.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Danielle Allen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lindsay B.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Kathy Miller",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Justin S.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Tami Rojas",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Rebekah S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.8,
        "customer_name": "Belinda Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jeremy M.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-27",
        "review_rate": 5.0,
        "customer_name": "Robin Gardner",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Laura M.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-30",
        "review_rate": 5.0,
        "customer_name": "Zachary Riley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Angela G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-05",
        "review_rate": 5.0,
        "customer_name": "Kathy White",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Janice M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.9,
        "customer_name": "Rebecca Snow",
        "service_type": "Garage Door Off Track",
        "tech_name": "Melissa G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Paula Case",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christine B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Mark Pace",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Steven O.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Emily Cruz",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lisa R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Christopher Watson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Darryl R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Sarah Fuller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Edward H.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-23",
        "review_rate": 4.8,
        "customer_name": "Ronald Nguyen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Barbara B.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Alyssa Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Raymond J.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-22",
        "review_rate": 5.0,
        "customer_name": "Jeffery Hamilton",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Emily H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Summer Nguyen",
        "service_type": "Garage Door Opener",
        "tech_name": "Angela P.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-15",
        "review_rate": 4.9,
        "customer_name": "Dustin Kline",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Stacey A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Traci Young",
        "service_type": "Garage Door Repair",
        "tech_name": "Jessica R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.8,
        "customer_name": "Crystal White",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sandy M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Caroline Bailey",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Joshua Green",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Charles S.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.8,
        "customer_name": "Belinda Blackburn",
        "service_type": "Garage Door Services",
        "tech_name": "Jeffrey T.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Victoria Scott",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Teresa G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Andre Scott",
        "service_type": "Garage Door Repair",
        "tech_name": "Amy S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.8,
        "customer_name": "Christina Lyons",
        "service_type": "Garage Door Opener",
        "tech_name": "Amy R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Jon Parker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jessica C.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.8,
        "customer_name": "Bryan Floyd",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Michael A.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Gabriela Gomez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lauren H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.8,
        "customer_name": "Danielle Dougherty",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Cheryl Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Haley S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Jonathan Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Eric G.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Zachary Wall",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sarah V.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Brittany Love",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cory L.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Rachel Young",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam A.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Lauren Jones",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jeremy H.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Nichole Contreras",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christopher M.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Isaac Adams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Wendy S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Lisa Owens",
        "service_type": "Garage Door Installation",
        "tech_name": "Bradley L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Michael Wright",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Angela M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 5.0,
        "customer_name": "Maria Hayden",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Gabriella Mclaughlin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrew A.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Pamela Welch",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jennifer H.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.8,
        "customer_name": "Kenneth Jackson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Karen M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Jeremy Mueller",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher D.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Paul Williams",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Robert P.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Bruce Mcintosh",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Todd V.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Susan Henry",
        "service_type": "Garage Door Services",
        "tech_name": "David T.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.9,
        "customer_name": "Daniel Stokes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kerry P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.8,
        "customer_name": "Andrew Gibson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Eric W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Hannah Shelton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Judy Mcbride",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Donna E.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.8,
        "customer_name": "Wendy Mcdonald",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kimberly T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Laura Goodman",
        "service_type": "Garage Door Insulation",
        "tech_name": "Breanna M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.9,
        "customer_name": "Yolanda Harrington",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael S.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Shawn Khan",
        "service_type": "Garage Door Opener",
        "tech_name": "Shari R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Brandon Bowen",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Christopher S.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Amanda Cole",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Victoria S.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Amy Martin",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jasmine C.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Connie Roberts",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Maria Roach",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alison B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "April Perez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kimberly W.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Angela Ramsey",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Drew S.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "David Owen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Harold M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Sandra Brooks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Paula P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Justin Ellis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Abigail C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Alan Moore",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sarah Y.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-10",
        "review_rate": 5.0,
        "customer_name": "Paul Gilbert",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Nicole O.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Kimberly Edwards",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian T.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Schmitt",
        "service_type": "Garage Door Repair",
        "tech_name": "Michelle H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.9,
        "customer_name": "Rebecca Smith",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dean E.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-17",
        "review_rate": 5.0,
        "customer_name": "Kimberly Harrison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Emily D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-19",
        "review_rate": 4.9,
        "customer_name": "James Ross",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brian G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.9,
        "customer_name": "Brian Estrada Jr.",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Justin J.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Anthony Waters",
        "service_type": "Garage Door Insulation",
        "tech_name": "David W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Jack Potter",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ronald M.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Scott Pruitt",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sharon M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Molly Herrera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brendan Y.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.8,
        "customer_name": "Christopher Andrews",
        "service_type": "Garage Door Installation",
        "tech_name": "Roger C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-22",
        "review_rate": 5.0,
        "customer_name": "Chloe Rose",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael R.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Sarah Foley",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kyle R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Kristin Wright",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael Y.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Janet Santiago",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Susan C.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Harold Taylor",
        "service_type": "Garage Door Insulation",
        "tech_name": "Amanda G.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.8,
        "customer_name": "Lawrence Lee",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Belinda Scott",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Heather M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Michelle Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Katie M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Natalie Shields",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Dale W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Lauren Adams",
        "service_type": "Garage Door Installation",
        "tech_name": "Rebecca V.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.9,
        "customer_name": "Emily Vega DVM",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lauren B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Kimberly Wells",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ricky H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Nicholas Garcia",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mariah M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Kendra Scott",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Timothy Z.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Michael Shaw",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jennifer H.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Heather Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Adrian C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Benjamin Ochoa",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Aaron C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.8,
        "customer_name": "Julia Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Pamela C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Thomas Patterson",
        "service_type": "Garage Door Services",
        "tech_name": "Matthew H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Erika Hunt",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kelly W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Edward Shaw",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "William F.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-29",
        "review_rate": 5.0,
        "customer_name": "Katie Ryan",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Calvin L.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 4.9,
        "customer_name": "Angela Fuentes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Theresa G.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Susan Kirk",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kayla P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Victor Griffin",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Brittany B.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Susan Doyle",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Tracy L.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Mark Howe",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dawn S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Suzanne Wells",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Patrick N.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Dr. Diamond Tate",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Michael B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Kathryn Bailey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Renee L.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 5.0,
        "customer_name": "Robert Gordon",
        "service_type": "Garage Door Insulation",
        "tech_name": "Larry F.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Adriana Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Stacy R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Jennifer Simmons",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mario M.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Marissa West",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alyssa J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 5.0,
        "customer_name": "Thomas Nelson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Gary J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "Tammy Clements",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Johnny W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Betty Wallace",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Chad D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Cynthia Gibson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Charles B.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Thomas Nguyen",
        "service_type": "Garage Door Installation",
        "tech_name": "Juan J.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.8,
        "customer_name": "Julia Cook",
        "service_type": "Garage Door Installation",
        "tech_name": "Cheryl C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-30",
        "review_rate": 5.0,
        "customer_name": "Joseph Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Natalie R.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 5.0,
        "customer_name": "Gregory Taylor",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Mike C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Gregory Phillips",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Timothy S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Victoria Young",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gary M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Lynn Cummings",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Larry Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Alicia O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Joseph Long",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Wesley J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Meredith Sanders",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eric C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "James Carroll",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "April J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Danielle Trevino",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Cindy C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Brianna Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "William H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Katherine Carter",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kristen R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Andre Dillon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tracey B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Daniel Gill",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kevin H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Steven Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Omar N.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Matthew Smith",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alan P.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Holly Hinton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Terri T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.8,
        "customer_name": "Adam Murray",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rebecca S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Richard Taylor",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rebecca J.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Bradley Campbell",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shaun M.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Ryan Carroll",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Dennis C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.8,
        "customer_name": "Katie Beck",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Amanda A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Tara Baker",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Nicholas W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Henry Dunn",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Andrew K.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Joseph Carey",
        "service_type": "Garage Door Off Track",
        "tech_name": "Craig M.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Michele Bender",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kiara J.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Misty Beard",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robert W.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 5.0,
        "customer_name": "Brandon Gay",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tammy P.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-23",
        "review_rate": 5.0,
        "customer_name": "Whitney Morales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert M.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-11",
        "review_rate": 5.0,
        "customer_name": "Jordan Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kevin R.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Robert Carter",
        "service_type": "Garage Door Services",
        "tech_name": "Veronica R.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-05",
        "review_rate": 5.0,
        "customer_name": "Jennifer Nguyen",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alan D.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Brandon Freeman",
        "service_type": "Garage Door Opener",
        "tech_name": "John H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "John Torres",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Patricia L.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.8,
        "customer_name": "Matthew Hicks",
        "service_type": "Custom Garage Door Design",
        "tech_name": "James S.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Laura Galloway",
        "service_type": "Garage Door Services",
        "tech_name": "Cynthia M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Jennifer King",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Janet J.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Blake Shepherd",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Donna L.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-20",
        "review_rate": 5.0,
        "customer_name": "Kendra Allen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jessica T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-05",
        "review_rate": 4.9,
        "customer_name": "William Terry",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Leah H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Frank Foster",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katelyn F.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Mark Miller",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Yvonne H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Sarah Gonzalez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Amanda C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.9,
        "customer_name": "Anna Brown MD",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Caleb J.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Desiree Buchanan",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Rebecca H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Bradley Aguilar",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dominic O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Shannon Raymond",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Carol M.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.8,
        "customer_name": "Doris Robinson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Gregory M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-20",
        "review_rate": 5.0,
        "customer_name": "Sharon Nunez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Chelsey D.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Danielle Medina",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wesley C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Joshua Wang DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Jon Baker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Renee K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Noah Haney",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jasmine M.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Valerie Galvan",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anthony M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Jillian Summers",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jay T.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Nicole Sawyer",
        "service_type": "Garage Door Opener",
        "tech_name": "Chelsea T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-14",
        "review_rate": 5.0,
        "customer_name": "Nicole Adams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Timothy D.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Bryan Flores",
        "service_type": "Garage Door Off Track",
        "tech_name": "Paul H.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.9,
        "customer_name": "Andrea Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Barbara Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Carly P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Jason Coleman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Carlos N.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Jennifer Diaz",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sarah W.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.8,
        "customer_name": "Robert Chase",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda A.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-07",
        "review_rate": 5.0,
        "customer_name": "Sandra Jefferson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Terry L.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Joanne Reyes",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Ricardo D.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Mark Reed",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher L.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Mary Barr",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Angela G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Daryl White",
        "service_type": "Garage Door Repair",
        "tech_name": "Jonathan F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.8,
        "customer_name": "Richard Serrano",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Harold T.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Tracy Wade",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Ashley Ward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Frank M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "Kayla Mullins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Andrew S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.9,
        "customer_name": "Garrett Tate",
        "service_type": "Garage Door Off Track",
        "tech_name": "Dominique W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "John Roberts",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeffrey C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.8,
        "customer_name": "Hailey Rice",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Seth Z.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Peggy Gilmore",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Sharon C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Christopher Campos",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Joseph R.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Brian Martinez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Alexandra J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.8,
        "customer_name": "Andrew Hanson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Troy M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-17",
        "review_rate": 5.0,
        "customer_name": "Linda Dominguez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "David Martinez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Shelby R.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Nancy Nguyen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Hannah R.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "James Potter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew L.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Nicole Stokes",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Anne B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Kristine Mata",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Todd C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Jessica Hill",
        "service_type": "Garage Door Repair",
        "tech_name": "Todd S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Kevin Rice",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Eric P.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Christopher Mckenzie",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Courtney A.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-22",
        "review_rate": 5.0,
        "customer_name": "Douglas Lambert",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Larry M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "James Hall",
        "service_type": "Garage Door Repair",
        "tech_name": "Tommy B.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Claire Larson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cory H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-24",
        "review_rate": 5.0,
        "customer_name": "Joshua Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Mark S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Gerald King",
        "service_type": "Garage Door Services",
        "tech_name": "Caitlin S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Shannon Ingram",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Angelica D.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Samantha Jones",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rebecca F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Erin Kramer",
        "service_type": "Garage Door Repair",
        "tech_name": "Maria H.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Kimberly Castillo",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexandra O.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Zachary Parker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kelly R.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Deborah Grimes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Elizabeth C.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.8,
        "customer_name": "Anna Singleton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Taylor D.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Laura Jackson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michael C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.9,
        "customer_name": "Shawn Estrada",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Charles J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-08",
        "review_rate": 5.0,
        "customer_name": "Adrian Graves",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Catherine B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Ryan Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Raymond C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Erin Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Yolanda H.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Scott Oliver",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James C.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Gregory Ford",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Angela H.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Molly Sellers",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rachel B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.8,
        "customer_name": "David Jarvis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jacqueline T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.8,
        "customer_name": "Robert Gaines",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ariel L.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Melissa Vasquez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sarah E.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Mason Mcintosh",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lauren M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Brenda Sims",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Aaron Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kim G.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Stewart",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Breanna F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Mallory K.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.9,
        "customer_name": "Lori Davis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Colleen M.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Karen Smith",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Daniel H.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Sherry Chavez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Sarah A.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Janet Berger",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Wendy M.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Monica Butler",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Dave W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-17",
        "review_rate": 5.0,
        "customer_name": "Kevin Nichols",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Edward P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.8,
        "customer_name": "Paul Woods",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Sandra J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-07",
        "review_rate": 5.0,
        "customer_name": "John Chen",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lisa W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.8,
        "customer_name": "Ashley Fitzgerald",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Elizabeth Y.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Bianca Chung",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Ricky Y.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Frank Powell",
        "service_type": "Garage Door Insulation",
        "tech_name": "Emily B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Catherine Barron",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tamara P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Tommy Scott",
        "service_type": "Garage Door Opener",
        "tech_name": "Antonio P.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.8,
        "customer_name": "Derrick Salas",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin T.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
