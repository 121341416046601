import React from 'react'
import { useNavigate } from 'react-router-dom'
import './Footer.scss';
import Banner from '../../Images/Banner.png'
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationData from '../../Components/LocationAll/LocationData'

export default function Footer({ WebSitePages, navigateTo }) {
    const Navigate = useNavigate()
    return (
        <div className="Footer">
            <div className="Footer2">
                <div className="Footer2Logo" onClick={() => navigateTo("")}>
                    <h1>Garage Door<b> Fontana</b></h1>
                </div>
            </div>
            <div className="FooterTop">
                <div className="SiteMapDiv">
                    <h1>Fontana Garage Door</h1>
                    <div className="SiteMapDivList">
                        <a href="/">Home Page</a>
                        <a href="/book">Schedule Online</a>
                        <a href="/contact">Contact Us</a>
                        <a href="/services">Our Services</a>
                        <a href="/locations">Our Locations</a>
                        <a href="/blog">Blog</a>
                        <a href="/faq">Faqs</a>
                        <a href={`/reviews/${LocationData.find(a => a.City == ("Fontana").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>Reviews</a>
                        <a href="/sitemap">Site Map</a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Contact Us</h1>
                    <div className="SiteMapDivList">
                        <a target='_blank' href="">
                            Fontana, California
                        </a>
                        <a target='_blank' href="tel:951-612-9362">
                            951-612-9362
                        </a>
                        <a target='_blank' href="mailto:info@Fontanahimneysweep.org">
                            info@Fontanahimneysweep.org
                        </a>
                    </div>
                </div>
                <div className="SiteMapDiv">
                    <h1>Services</h1>
                    <div className="SiteMapDivList">
                        {AreaData.map((A, index) =>
                            <a key={index} href={A.Path} className="Header1BoxMenuBox">
                                {A.Title}
                            </a>
                        )}
                    </div>
                </div>

            </div>
            <div className="Footer3">
                <h1>Copyright 2024 Garage Door Fontana. All rights reserved. All available services, hours of operations, pricing structure, and guarantees may vary by location.</h1>
            </div>
        </div>
    )
}
